.navwrap{
  position:relative;

  .prev,.next{
    display: flex;
    position: absolute;
    top:0px;
    bottom:0px;
    width:40px;
    height: 100%;
    background-color: rgba(white,0.8);
    cursor: pointer;
    &:before{
      display: flex;
      align-items: center;
      justify-content: center;
      width:100%;
      font-size: 2.5em;
    }
  }
  .prev{
    right:0px;
    &:before{
      content: '\276C';
    }
  }
  .next{
    left:0px;
    &:before{
      content: '\276D';
    }
  }
  img.active{
    opacity: 0.75;
  }
  &#thumbPhoto img{
    cursor: zoom-in;
  }
  &#thumbnails img{
    cursor: pointer;
  }
}

.product-tabs .nav-link.active {
  color: $gray-900;
  background-color: $warning;
}
// .nav-link.active[href='#TellFriend']{
//   background-color:$gray-100;
//   border-bottom-color: $gray-100;
// }
.page-half>div{
  min-height: 60vh;
  padding: 2rem;
  //margin-bottom: 2.5rem;
}
.tab-content.page-half > .tab-pane{
  position:relative;
  bottom:42px;
  padding-top: 6rem;
}
//  comment stars
.starRadio{
  margin-right: 0.5rem;
  input[type='radio']{
    display:none;

    &+label{
      position:relative;
      width: 31px;
      height: 24px;

      &:before {
        content: "";
        position:absolute;
        display:inline-block;
        width: 24px;
        height: 24px;
        top:1rem;
        cursor: pointer;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAqgAAAKoBBgpwTwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAH/SURBVEiJrZWxa1NRFIe/817aDEoRHVpaUCqPoELFkhJxtCKxLoUgxcFu2tHBsVP/gCJF3HSULhHcVLTFDF36KJYulWDQ6GCXqiV1eZrc41CV1+Sm3pfmTJffPb/z3cu991xRVZxj8/JRKME5/eFq8dyrA709s0TMJrGI8w7K+WE8847dJUhxlvP60cXmvgPPzANpIE2deWebU1Y5Pw4UYkqBtzLeJcCUj28WLBMLFMU/PKDyfQZlxDIzwmlm/mdvf8ib146Trucw8gQ48U/fXYpnfQVuERFySb+1B1Sup+HXKKo5kBxwEQis4P2AeFSAVZQQIaSPdQKNRN9feYjKHaD3oK06AJrjJ/DIwzTmEKquLudQqihzHpk329RTeWCri+W38MmT1e29W3TmRRX1J4BaF4rXECa4oFWIX9PMyw2ESSA6RPEIwySjuvFX2P8OgtclRKYB00FxgzLNmJbiYutDC14VUV3sALBIVovNov0lC0c6AFg9bVqFN5S4vGD1tAFocoA6A6Z8YCAxAAZs3bUVUN7pB2xteAWVAnv/wopl3iegv1lMWRIHY+MGwlOMuU9mOYzpz1iTHB73gBuxBQ0CXw4G+DqEUkPkMX7jAcPLnyyLgDENgZusyykMdxFu/znotXha63/w4epJTM8OwfNkbWNV+khxjKx+jsu/AXUxm+7sduA/AAAAAElFTkSuQmCC') center center no-repeat;
      }
    }

    &:checked ~ input[type='radio']+label:before{
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiBg0PFQtBSUX1AAAA/klEQVQ4y6WSsQrCMBBAn3ZwsDhIwUGwFdxFkLqKk5sg+BH+iFs3/8DFxb9w0UVwE5w7uXRroSUutY22qREvSzjeu8slgfIwMfkpNmx+wfuEhPT1hQMCwUEXnyHSNdPBDa6ZcMX4LqwzXCBYV8Nt5jzehAdz2jJSAxqMcHGZMFAUunPizJkLEWyJ3mpWrYgtWNy0hRsWgIOvhfs4rzMOCb7iAUN5rClhJR4y/byJFYkST1iVXd5OKexyqC4JTeWDNsuFrlLo/iHkYRArZ4jzX5t36Ehf+ciSJUepWKfYYZzW2uNmOZd92ndcFBYEeNiFvI1HwKIo9Ggph27Re22fDa/CdtJlpxcAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMDYtMTNUMTU6MjE6MTErMDI6MDAH0k5WAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTA2LTEzVDE1OjIxOjExKzAyOjAwdo/26gAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=') center center no-repeat;
    }

  }
}
