﻿/**
*
*	Name:			IRAN Font
*	Version:		4.0
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		Dec 25, 2012
*	Updated on:		Dec 12, 2015
*	Website:		http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
خانواده فونت ایران یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
IRAN Family typeface fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
--------------------------------------------------------------------------------------
*	
**/


//@font-face {
//    font-family: IRAN;
//    font-style: normal;
//    font-weight: bold;
//    font-display: swap;
//    src: local('tahoma'),url('/dist/fonts/IRAN/eot/IRANBold.eot');
//    src: url('/dist/fonts/IRAN/eot/IRANBold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
//    url('/dist/fonts/IRAN/woff2/IRANBold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
//    url('/dist/fonts/IRAN/woff/IRANBold.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//    url('/dist/fonts/IRAN/ttf/IRANBold.ttf') format('truetype');
//}

//@font-face {
//    font-family: IRAN;
//    font-style: normal;
//    font-weight: 900;
//    font-display: swap;
//    src: local('tahoma'),url('/dist/fonts/IRAN/eot/IRANBlack.eot');
//    src: url('/dist/fonts/IRAN/eot/IRANBlack.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
//    url('/dist/fonts/IRAN/woff2/IRANBlack.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
//    url('/dist/fonts/IRAN/woff/IRANBlack.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//    url('/dist/fonts/IRAN/ttf/IRANBlack.ttf') format('truetype');
//}

//@font-face {
//    font-family: IRAN;
//    font-style: normal;
//    font-weight: 600;
//    font-display: swap;
//    src: local('tahoma'),url('/dist/fonts/IRAN/eot/IRAN_SemiBold.eot');
//    src: url('/dist/fonts/IRAN/eot/IRAN_SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
//    url('/dist/fonts/IRAN/woff2/IRAN_SemiBold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
//    url('/dist/fonts/IRAN/woff/IRAN_SemiBold.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//    url('/dist/fonts/IRAN/ttf/IRAN_SemiBold.ttf') format('truetype');
//}

@font-face {
    font-family: IRAN;
    //font-style: normal;
    //font-weight: normal; 
    font-display: swap;
    src: local('tahoma'),url('/dist/fonts/IRAN/eot/IRAN.eot');
    src: url('/dist/fonts/IRAN/eot/IRAN.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('/dist/fonts/IRAN/woff2/IRAN.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
    url('/dist/fonts/IRAN/woff/IRAN.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('/dist/fonts/IRAN/ttf/IRAN.ttf') format('truetype');
}