﻿.Footer {
    background-color: $color_Baltic_Sea;

    h4 {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    a {
        color: $gray-500;
        line-height: 2.3em;

        &:hover {
            color: $white;
        }
    }

    a.fa-news-bullet {
        &:before {
            content: '';
            display: inline-block;
            margin: 0 0 -0.5em 0.4em;
        }
    }
}

.tags {
  a {
      display: inline-block;
      margin: 0.5em 0.2em;
      padding: 0.3em 0.5em;
      border: 2px solid $gray-300;
      border-radius: 0.2em;

      &:hover {
          border-color: $warning!important;
          background-color: $warning!important;
          color: $dark!important;
      }
  }
  &.tags-dark a{
    color: rgba($white, .5);
    border-color:#444;
  }
}
/*#content {
    position: relative;
    min-height: 100vh;

    > div {
        padding-bottom: 16.2rem +0.3rem;
    }
}
#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 16.2rem;
    background-color: #191919;
}*/
