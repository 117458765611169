/* glue: 0.13 hash: 1cdc30b47b */
        .fa-user,
.fa-shopping-basket,
.fa-shopping-basket-white,
.fa-return,
.fa-menu,
.fa-lifebuoy,
.fa-delivery-truck,
.fa-phone-call,
.fa-location,
.fa-email,
.fa-close,
.fa-star,
.fa-star-black,
.fa-news-bullet:hover:before,
.fa-news-bullet:before {
            background-image: url('../../dist/img/icons.png');
            background-repeat: no-repeat;
        }
        
        .fa-user {
            background-position: 0 0;
            width: 32px;
            height: 32px;
        }
        
        .fa-shopping-basket {
            background-position: -32px 0;
            width: 32px;
            height: 32px;
        }
        
        .fa-shopping-basket-white {
            background-position: 0 -32px;
            width: 32px;
            height: 32px;
        }
        
        .fa-return {
            background-position: -32px -32px;
            width: 32px;
            height: 32px;
        }
        
        .fa-menu {
            background-position: -64px 0;
            width: 32px;
            height: 32px;
        }
        
        .fa-lifebuoy {
            background-position: -64px -32px;
            width: 32px;
            height: 32px;
        }
        
        .fa-delivery-truck {
            background-position: 0 -64px;
            width: 32px;
            height: 32px;
        }
        
        .fa-phone-call {
            background-position: -32px -64px;
            width: 24px;
            height: 24px;
        }
        
        .fa-location {
            background-position: -56px -64px;
            width: 24px;
            height: 24px;
        }
        
        .fa-email {
            background-position: -96px 0;
            width: 24px;
            height: 24px;
        }
        
        .fa-close {
            background-position: -96px -24px;
            width: 24px;
            height: 24px;
        }
        
        .fa-star {
            background-position: -96px -48px;
            width: 16px;
            height: 16px;
        }
        
        .fa-star-black {
            background-position: -96px -64px;
            width: 16px;
            height: 16px;
        }
        
        .fa-news-bullet:hover:before {
            background-position: -96px -80px;
            width: 16px;
            height: 16px;
        }
        
        .fa-news-bullet:before {
            background-position: -80px -64px;
            width: 16px;
            height: 16px;
        }
        
        @media screen and (-webkit-min-device-pixel-ratio: 1.0), screen and (min--moz-device-pixel-ratio: 1.0), screen and (-o-min-device-pixel-ratio: 100/100), screen and (min-device-pixel-ratio: 1.0), screen and (min-resolution: 1.0dppx) {
            .fa-user,
    .fa-shopping-basket,
    .fa-shopping-basket-white,
    .fa-return,
    .fa-menu,
    .fa-lifebuoy,
    .fa-delivery-truck,
    .fa-phone-call,
    .fa-location,
    .fa-email,
    .fa-close,
    .fa-star,
    .fa-star-black,
    .fa-news-bullet:hover:before,
    .fa-news-bullet:before {
                background-image: url('../../dist/img/icons.png');
                -webkit-background-size: 120px 96px;
                -moz-background-size: 120px 96px;
                background-size: 120px 96px;
            }
        }
        