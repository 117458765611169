﻿%closeLine {
    content: "";
    display: block;
    width: 33px;
    height: 3px;
    background-color: white;
    margin: 6px 5px;
}
.rmmToggle {
    position: relative;
    padding: 6px 0px;
    width: 46px;
    height: 46px;
    margin-right:12px;

    &:before, span:before, span:after {
        @extend %closeLine;
        background-color: #222;
    }
}
.rmm {
    margin: 0 auto;
    display: inline-block;
    text-align: right;

    > div {
        display: none;
    }

    &.open {
        background-color: rgba(255, 255, 255, 1); //rgba(255, 255, 255, 0.87);
        position: fixed;
        right: 0px;
        left: 0px;
        top: 0px;
        bottom: 0px;
        z-index: 7;
        /****************/
        > div {
            position: relative;
            border: 1px solid black;
            border-radius: 3px;
            padding: 6px 0px;
            width: 46px;
            height: 46px;
            margin: 20px auto 0 12px;
            display: block;

            span {
                &:before, &:after {
                    @extend %closeLine;
                    background-color: black;
                    position: absolute;
                    top: 15px;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &:not(.open) {
        > ul {
            display: none !important;
        }
    }

    ul {
        margin: 0;
        list-style: none;
        position: relative;
        padding: 0;
    }

    > ul {
        width: 100%;
        margin: 0;
        list-style: none;
        height: 100%;
        /*position: fixed;*/
        /*bottom: 0px;*/
        overflow: auto;
        /*padding: 1rem 0;
        top: 75px;*/

        .rmm-back {
            display: block;

            a {
                &:before, &:after {
                    @extend %closeLine;
                    width: 20px;
                    right: 8px;
                    position: absolute;
                }

                &:before {
                    transform: rotate(45deg);
                    top: 14px;
                }

                &:after {
                    transform: rotate(-45deg);
                    top: 25px;
                }
            }
        }

        .rmm-dropdown {
            display: block !important;
        }

        li {
            display: block;
            text-align: center;
            //width: 100%;
            /*background-color: $list-group-bg;
            border: $list-group-border-width solid $list-group-border-color;*/
            > a:not(:only-child):after {
            }

            a {
                display: block;
                text-decoration: none;
                padding: 1rem 1.25rem;
                //color: #333333;
            }

            &:first-child {
                border-top-left-radius: .25rem;
                border-top-right-radius: .25rem;
            }

            &:last-child {
                border-bottom-right-radius: .25rem;
                border-bottom-left-radius: .25rem;
            }

            & + .list-group-item {
                border-top-width: 0;
            }

            > a span {
                position: absolute;
                left: 5px;
                margin: 0px;
                margin-top: -15px;
                border: 1px solid black;
                border-radius: 3px;
                padding: 6px 0px;
                width: 46px;
                height: 46px;

                &:before, &:after {
                    @extend %closeLine;
                    background-color: black;
                    width: 17px;
                    left: 8px;
                    position: absolute;
                }

                &:before {
                    transform: rotate(-45deg);
                    top: 9px;
                }

                &:after {
                    transform: rotate(45deg);
                    top: 20px;
                }
            }

            > a:not(:only-child):after {
            }
        }

        ul {
            width: 100%;
            display: none;
            margin: 0;
            padding: 0;
            top: 100%;
            border-radius: 0px;
            position: absolute;

            li {
                position: relative;
                /*border-bottom: 1px solid #575F6A;
                border-top: 1px solid #6B727C;*/
                > a:not(:only-child):after {
                }
            }

            ul {
                position: absolute;
                left: 100%;
                top: 0;
                width: 100%;
            }
        }

        .rmm-subview {
            display: block !important;
            width: 100%;
        }

        .rmm-subover-hidden {
            display: none !important;
        }

        .rmm-subover-visible {
            display: block;
        }

        .rmm-subover-header {
            display: none !important;
            padding: 0;
            margin: 0;
        }

        .rmm-submenu {
            position: relative;
            left: 0px;
        }
    }

    .rmm-back {
        display: none;

        a:before {
            //@include icons('right-arrow',16px)
        }

        &:after {
            display: none;
        }

        ul:after {
            content: "";
            clear: both;
            display: block;
        }
    }
}
