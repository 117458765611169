﻿header {
    .top-navbar {
        min-height: 40px;
        background-color: $color_Baltic_Sea;
        color: $color_Smokey_Grey;

        .navbar {
            padding-top: 0px;
            padding-bottom: 0px;

            .navbar-text, .btn-link {
                color: $color_Smokey_Grey;
            }

            .btn-link:hover {
                color: $color_Rose_Fog;
            }
        }
    }

    .navigation {
        @include media-breakpoint-up(lg) {
            min-height: 96px;
        }

        @include media-breakpoint-down(md) {
            min-height: 68px;
            font-size: 0.708em;
        }

        .logo {
            @include media-breakpoint-up(lg) {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            @include media-breakpoint-down(md) {
                padding-top: 0.375rem;
                padding-bottom: 0.375rem;
            }


            img {
                @include media-breakpoint-down(md) {
                    height: 56px;
                }
            }
        }

        a.cart {
            position: relative;
            padding: 0px;
            border: none;

            > div {
                padding: 0.5rem;
                border-radius: 0.25rem;
            }

            span.badge {
                display: block;
                position: absolute;
                top: 12px;
                right: 3px;
            }
        }
    }

    nav.nav {
        a {
            color: black;
            /*@include media-breakpoint-up(lg) {
                color: white;
            }*/
        }
    }
}
#searchPane {
    background-color: rgba(white,0);
    transition: background-color 0.3s;
}
/*$headHeight:88px;
$headPadding:25px;
$headBackground: black;
$HdrTrDur: 1s;
$headerTransition: padding $HdrTrDur,height $HdrTrDur,background-color $HdrTrDur;

.Header {
    background-color: rgba( $headBackground, .4 );
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 7;
    color: white;
    padding-top: 0;
    height: $headHeight;

    a, .btn {
        color: white;
    }

    @media (max-width: 991.98px) {
        transition: $headerTransition;
        padding-top: 0 !important;
        height: $headHeight !important;
        background-color: $headBackground !important;
        position: static !important;
    }

    &.sticky {
        background-color: rgba( $headBackground, .7 ) !important;
        transition: $headerTransition;
        padding-top: 0 !important;
        height: $headHeight !important;
    }

    .Logo {
        display: block;
        width: 180px;
        height: 80px;
        margin-bottom: 8px;
    }
}

body {
    &.Home .Header {
        transition: $headerTransition;
        padding-top: $headPadding;
        height: $headHeight+$headPadding;
    }

    &.Internal .Header {
        background-color: $headBackground !important;
        position: static !important;
    }
}

.MWTH {
    position: relative;
    top: $headHeight+$headPadding;

    @media (max-width: 991.98px) {
        top:0;
    }

    z-index: 6;
    height: 0px;

    a {
        //color: white;
    }
}*/