﻿.hover-warning {
    &:hover,&:active{
        color:$warning!important;
    }
}
.advantage > div+div {
    @include media-breakpoint-up(sm) {
        border-right: 1px solid #dee2e6;
    }
}
.animated-border {
    $animatedBorderColor: rgba(255, 255, 255, 0.8);
    position: relative;
    padding: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;

    &:before, &:after {
        content: "";
        display: block;
        opacity: 0;
        position: absolute;
        top: 15px;
        left: 15px;
        bottom: 15px;
        right: 15px;
        z-index: 9;
    }

    &:before {
        border-top: 1px solid $animatedBorderColor;
        border-bottom: 1px solid $animatedBorderColor;
        right: 20%;
        left: 20%;
    }

    &:after {
        border-right: 1px solid $animatedBorderColor;
        border-left: 1px solid $animatedBorderColor;
        top: 20%;
        bottom: 20%;
    }

    &:hover {
        &:before, &:after {
            opacity: 1;
            transition: .3s top ease, .3s right ease, .3s bottom ease, .3s left ease,;
        }

        &:before {
            right: 15px;
            left: 15px;
        }

        &:after {
            top: 15px;
            bottom: 15px;
        }
    }
}
#slider {
    position: relative;
    width:100%;
    .item {
        position: relative;

        > div {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }

        > img {
            width: 100%;
        }
    }

    @-webkit-keyframes tranKeyFrames {
        from {
            left: -100%;
        }

        to {
            left: 100%;
        }
    }

    > span {
        display:block;
        position: absolute;
        width: 100%;
        top: 0px;
        bottom: 0px;

        &.active {
            animation-name: tranKeyFrames;
            animation-duration: 1000ms;
            animation-iteration-count: 1;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
        }

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-color: #00000088;
        }

        > img {
            width: 100%;
        }
    }
}
@-webkit-keyframes anim-from-top {
    from {
        top: -700px;
    }
    to {
        top: 0 ;
    }
}
@-webkit-keyframes anim-from-right {
    from {
        right: -100%;
    }

    to {
        right: 0;
    }
}
@-webkit-keyframes anim-from-bottom {
    from {
        bottom: -700px;
    }

    to {
        bottom: 0;
    }
}
@-webkit-keyframes anim-from-left {
    from {
        left: -100%;
    }

    to {
        left: 0;
    }
}
.b-animation {
    position: relative;

    &.activeB {
        animation-duration: 2000ms;
        animation-iteration-count: 1;
        animation-timing-function: ease;
        animation-fill-mode: forwards;

        &.from-top {
            animation-name: anim-from-top;
        }

        &.from-right {
            animation-name: anim-from-right;
        }

        &.from-bottom {
            animation-name: anim-from-bottom;
        }

        &.from-left {
            animation-name: anim-from-left;
        }
    }
}


.carousel {
    position: relative;

    $controlSize: 50px;

    > a {
        position: absolute;
        top: 50%;
        margin-top: -$controlSize/2;
        width: $controlSize;
        height: $controlSize;
        padding: 0px;
        background-color: rgba(white,0.8);
        cursor: pointer;
        border-radius: 50em;
        font-family: Arial,Verdana,Tahoma;
        direction: ltr;
        font-size: 1.5em;
        text-align: center;
        line-height: 2em;
        color: #bbb;
        border: 2px solid #eee;
        z-index: 4;

        &:hover {
            box-shadow: 0px 0px 3px #ccc;
            background-color: $yellow;
            border-color: $yellow;

            &:before {
                color: black;
            }
        }

        &:active {
            background-color: rgba(white,0.8);
            border-color: #eee;
        }

        &.next {
            left: 0px;

            &:before {
                content: "<";
            }
        }

        &.prev {
            right: 0px;

            &:before {
                content: ">";
            }
        }
    }
}

.product {
    > div {
        margin: 0.5em 0.35em;
    }

    a {
        display: block;
    }

    [itemprop="image"] {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        height: 300px;
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
            max-height: 300px;
            margin: 0 auto;
            border-radius: 3px;
        }
    }

    [itemprop="price"] {
        text-align: left;
        font-size: 1.2rem;
        //line-height: 2em;
        display: block;
        padding: 0 0.5em;
        color: $dark;

        &:after {
            content: ' تومان';
            font-size: 0.8em
        }

        &.na:after {
            content: 'ناموجود';
            color: $text-muted;
        }
    }

    [itemprop="title"] {
        line-height: 1.5em;
        min-height: 3.5em;
        padding: 0.5em;
        text-align: right;
        margin: 0px;
        font-size: 0.85em;
        color: $dark;
    }

    [itemprop="addtocart"] {
        padding: 0.6em;

        a {
            display: block;
            border-radius: 50em;

            &:after {
                content: 'افزودن به سبد خرید';
            }
        }

        &.na a:after {
            content: 'جــزئیات';
        }
    }

    &.full > div {
        border-radius: 3px;

        &:hover {
            box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
        }
    }

    &.mini {
        > div > a {
            height: 150px;

            img {
                max-height: 150px;
            }
        }

        border-top: 1px solid #eeeeee;

        @include media-breakpoint-up(md) {
            &:nth-child(3n+2), &:nth-child(3n+3) {
                > div {
                    border-right: 1px solid #eeeeee;
                    margin: 1.5em 0.35em!important;
                }
            }

            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                border-top: none;
            }
        }
    }
}

#tagLinks a {
    color: $text-muted;
    cursor:pointer;
    &.active {
        color: $dark;
        border-bottom-color: $warning;
    }

    &.active, &:hover {
        border-top-color: rgba(white,0);
        border-right-color: rgba(white,0);
        border-left-color: rgba(white,0);
    }
}

/*#slider {
    position: relative;
    min-height: $headHeight+$headPadding+40px;

    @media (max-width: 991.98px) {
        min-height: 40px;
    }

    .item {
        display: none;
        top: 0px;
        width: 100%;
        overflow: hidden;

        > video {
            display: block;
        }

        > img, > video {
            min-width: 101%;
            width: 100vh;
            max-width: 140vw;
            height: auto;
            padding: 0px;
            margin: 0px;
            object-fit: fill;
            transform: translate(50%);
            margin-right: 50%;
        }
    }

    .overlay {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;

        div {
            position: relative;
            height: 100%;

            .overlayText {
                padding-top: 33%;

                h5 {
                    margin: 0px;
                    padding: 6px 10px;
                    display: inline;
                    background-color: #634329b3;
                    color: white;
                    line-height: 48px;
                }
            }

            .overlayNav {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 100%;
                height: 100vh;
                max-height: 100%;
                z-index: 3;

                span {
                    $snavpadding: 12px;
                    $arrowT: 16px;
                    $arrowB: 12px;
                    position: absolute;
                    bottom:10px;
                    z-index: 3;
                    width: 60px;
                    height: 60px;
                    $arrowColor: black;
                    background-color: rgba( $arrowColor, .7 );
                    cursor: pointer;

                    &:hover {
                        background-color: $arrowColor;
                    }

                    &:before, &:after {
                        @extend %closeLine1;
                        width: 20px;
                        right: 14px;
                        position: absolute;
                    }

                    &.prev {
                        right: $snavpadding;

                        &:before {
                            transform: rotate(45deg);
                            top: $arrowT;
                        }

                        &:after {
                            transform: rotate(-45deg);
                            top: $arrowT+$arrowB;
                        }
                    }

                    &.next {
                        right: 60px + $snavpadding + 6px;

                        &:before {
                            transform: rotate(-45deg);
                            top: $arrowT;
                        }

                        &:after {
                            transform: rotate(45deg);
                            top: $arrowT+$arrowB;
                        }
                    }
                }
            }
        }
    }
}
.PaleTaupe {
    background-color: #bd9c81;

    .tickerEngine {
        padding-top: 16px;
        padding-bottom: 16px;

        span {
            color:white;
            &:after {
                content: '|';
                animation: 1s linear infinite condemned_blink_effect;
            }

            @keyframes condemned_blink_effect {
                0% {
                    visibility: hidden;
                }

                50% {
                    visibility: hidden;
                }

                100% {
                    visibility: visible;
                }
            }
        }
    }
}

.counting {
    color: #ff6b5a;
    font-weight: bold;
    text-shadow: 0px 0px 2px black;
}
.videoPart > .container-xl {
    background-color: #1d2c4e;
}

*/