﻿
/*** CSS OUTPUT LOCALLY FROM SCSS AND MODIFIED FOR CODEPEN ***/

/*** There are a lot of things about Superfish that I don't love;
     in particualar, I don't love the mobile application. Further
     modification would be needed to make the mobile-nav a click-
     based setup even in a touch-free environment. Anyway, this is
     the fastest solution to style but not necessearily the best
     responsive pattern. Still, it's functional enough for the
     purposes that the question asker needed. ***/

/*** Core Superfish Menu Styles ***/
%closeLine_products {
    content: "";
    display: block;
    width: 33px;
    height: 3px;
    background-color: white;
    margin: 6px 5px;
}
.rmm {
    margin: 0px;
    display: inline-block;

    > div {
        display: none;
    }

    > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;

        * {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        ul {
            position: absolute;
            display: none;
            top: -999em;
            width: 10em;
            margin: 0;


            li {
                width: 100%;

                a {
                    @include font-size($font-size-base);

                    &:hover {
                        color: $light;
                    }
                }

                .sub-indicator {
                    border: 7px solid rgba(0, 0, 0, 0) !important;
                    border-right: 7px solid #73aebb !important;
                    position: absolute;
                    left: 7px;
                    top: 18px;
                }
            }

            ul {
                margin-right: 9.9em;
            }
        }

        > li > a {
            line-height: 3.15rem;
        }

        > li:first-child {


            a {
                white-space: nowrap;
                text-indent: -9999px;
                padding: 0px;
            }



            a:before {
                top: 0px
            }
        }

        li {
            display: inline-block;
            color: #dddddd;
            //line-height: 4em;
            position: relative;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }

            &:hover {
                color: #f3d057;
                visibility: inherit;

                > a {
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 0;
                        z-index: 999;
                    }
                }

                ul {
                    display: block;
                    right: 0;
                    top: 3.15em;
                    //border: 1px solid #80B500;
                    z-index: 99;
                }

                li ul {
                    top: -999em;
                }
            }

            ul {
                background-color: rgba($dark,0.9);
                padding: 0;

                li {
                    border-right: none;
                    border-left: none;

                    & + li {
                        border-top: 1px solid $secondary;
                    }

                    &:hover {
                        padding-top: 0px;
                        /*-webkit-transition-property: all;
                        -moz-transition-property: all;
                        -o-transition-property: all;
                        transition-property: all;
                        -webkit-transition-duration: 0.5s;
                        -moz-transition-duration: 0.5s;
                        -o-transition-duration: 0.5s;
                        transition-duration: 0.5s;*/
                        background-color: $dark;

                        a:after {
                            border: none;
                        }
                    }

                    &:first-child {
                        //border-top: none;
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    a {
                        //padding: 18px;
                        padding: 10px;
                        //line-height: 1em;
                        color: $light;
                        text-align: center;
                    }
                }
            }

            a {
                position: relative;
                text-decoration: none;
                padding: 0 20px;
                z-index: 1;
                display: block;
                /*&:before {
                    background: white;
                    display: block;
                    position: absolute;
                }*/
                &:hover {
                    color: $color_Rose_Fog;

                    &:before {
                        /*top: 0;*/
                        /*-webkit-transition: 0.4s;
                        -moz-transition: 0.4s;
                        -o-transition: 0.4s;
                        transition: 0.4s;*/
                    }
                }
            }

            .sub-indicator {
                margin-right: 4px;
                margin-bottom: -3px;
                display: inline-block;
                width: 0;
                height: 0;
                border: 4px solid rgba(0, 0, 0, 0);
                border-top: 4px solid #73aebb;
                z-index: 1;
            }

            li {
                &:hover {
                    ul {
                        left: 10em;
                        /* match ul width */
                        top: 0;
                        //margin-right: 13em;
                    }

                    li ul {
                        top: -999em;
                    }
                }

                li:hover ul {
                    left: 10em;
                    /* match ul width */
                    top: 0;
                }
            }
        }

        .rmm-back {
            display: none;
        }
    }
}

           
