.preload * {
    transition: none !important;
}

html {
    scroll-behavior: smooth;
}

.underline-hover:hover, .underline, .underline-all, .underline-all:hover {
    text-decoration: underline;
}

.bp-lazy[data-src] {
    width: 100%;
    height: 100%!important;
}

%line {
    content: "";
    display:block;
    width: 33px;
    height: 2px;
    background-color: #333;
    transition: .3s all ease;
}
%closeLine1 {
    content: "";
    display: block;
    width: 33px;
    height: 3px;
    background-color: white;
    margin: 6px 5px;
}

@import 'variables';
@import 'icons';
@import 'header';
@media (max-width: 991.98px) { @import 'responsivemultimenu'; }
@media (min-width: 992px) { @import 'TopResponsiveMenustyle'; }
@import 'home';
// @import '_internal';
@import 'product';
@import '_footer';
.pill-r {
    border-top-right-radius: $header-search-radius !important;
    border-bottom-right-radius: $header-search-radius !important;
}
.pill-l {
    border-top-left-radius: $header-search-radius !important;
    border-bottom-left-radius: $header-search-radius !important;
}
